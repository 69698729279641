<template>
  <div v-bind="$attrs" class="compact-events-list">
    <CompactEvent
      v-for="event in limitedEvents"
      :key="event.id"
      :event="event"
      :highlight="event === nextEvent"
      :displayRegistrations="displayRegistrations"
      @click="() => eventClicked(event)"
    />
    <div v-if="displayMoreEventsLink && showMoreEventsLink" class="mt-2">
      <i class="fa fa-calendar-o c-brand-color me-2"></i>
      <NuxtLink
        class="undecorated-router-link"
        :to="{
          name: 'studio-calendar',
        }"
      >
        {{ $t('EVENTS.BUTTON.SHOW_ALL_UPCOMING_EVENTS') }}
      </NuxtLink>
    </div>
  </div>
</template>

<script>
import CompactEvent from '~/components/events/CompactEvent.vue';
import { useRoute } from 'vue-router';

export default {
  name: 'CompactEventsList',
  components: {
    CompactEvent,
  },
  props: {
    events: Array,
    nextEvent: Object,
    openEventDetailsOnClick: {
      type: Boolean,
      default: true,
    },
    maxEvents: {
      type: Number,
      default: 5,
    },
    showMoreEventsLink: {
      type: Boolean,
      default: false,
    },
    displayRegistrations: {
      type: Boolean,
      default: false,
    },
    openInNewWindow: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const route = useRoute();
    return {
      route,
    };
  },
  computed: {
    limitedEvents() {
      if (this.maxEvents === 0) return this.events;
      return this.events.slice(0, this.maxEvents);
    },
    displayMoreEventsLink() {
      return this.events.length > this.maxEvents;
    },
  },
  methods: {
    eventClicked(event) {
      if (this.openInNewWindow) {
        const routeData = this.$router.resolve({
          name: 'studio-event-details',
          params: {
            studio_url: this.$studioUrl,
            event_id: event.id,
          },
        });
        window.open(routeData.href, '_blank');
      } else if (this.openEventDetailsOnClick) {
        this.$router.push({
          name: 'studio-event-details',
          params: {
            studio_url: this.$studioUrl,
            event_id: event.id,
          },
        });
      } else {
        this.$emit('onEventClick', event);
      }
    },
  },
};
</script>

<style scoped></style>
  